<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentTemplate.id == null">
            <h2 class="title is-3">Создать шаблон</h2>
          </div>
          <div class="column" v-if="currentTemplate.id != null">
            <h2 class="title is-3">Изменить шаблон: {{ currentTemplateName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentTemplate.name"></b-input>
            </b-field>

            <div v-if="currentTemplate.user_access">
              <v-divider/>
              <label class="label">Доступы</label>

              <b-field class="mt-4" label="Все пользователи">
                <b-switch
                  @input="onAllUsersChange"
                  v-model="currentTemplate.user_access.all">
                  {{ currentTemplate.user_access.all ? 'Да' : 'Нет' }}
                </b-switch>
              </b-field>

              <div v-if="!currentTemplate.user_access.all">
                <p class="error--text">Когда выбираете человека в группах пользователей, доступ будет у него и ВСЕЙ ЕГО КОМАНДЫ</p>

                <v-select
                  outlined
                  v-model="currentTemplate.user_access.subordinates_of"
                  label="Группы пользователей"
                  :items="teamleads"
                  item-value="id"
                  item-text="name"
                  multiple
                  class="mt-7"
                ></v-select>

                <v-select
                  class="mb-3"
                  outlined
                  v-model="currentTemplate.user_access.users"
                  label="Пользователи"
                  :items="profiles"
                  item-value="id"
                  item-text="name"
                  multiple
                ></v-select>
              </div>
              <v-divider/>
            </div>

            <b-field label="URL папки с шаблоном">
              <b-input v-model="currentTemplate.offer_path" disabled></b-input>
            </b-field>
            <b-field label="Шаблон(в формате .zip)">
              <div class="columns">
                <div class="column is-6">
                  <b-field class="file is-primary" :class="{'has-name': !!currentTemplate.offer}">
                    <b-upload v-model="currentTemplate.offer" class="file-label" accept=".zip">
                      <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Click to upload</span>
                      </span>
                      <span class="file-name" v-if="currentTemplate.offer">
                          {{ currentTemplate.offer.name }}
                      </span>
                    </b-upload>
                  </b-field>
                </div>
              </div>
            </b-field>
            <b-field label="URL изображения">
              <b-input v-model="currentTemplate.img_path" disabled></b-input>
            </b-field>
            <b-field label="Превью(скрин) шаблона">
              <div class="columns">
                <div class="column is-6">
                  <b-field class="file is-primary" :class="{'has-name': !!currentTemplate.img}">
                    <b-upload v-model="currentTemplate.img" class="file-label" accept="image/*">
                      <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Click to upload</span>
                      </span>
                      <span class="file-name" v-if="currentTemplate.img">
                          {{ currentTemplate.img.name }}
                      </span>
                    </b-upload>
                  </b-field>
                </div>
              </div>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentTemplate.id == null" @click="createTemplate()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentTemplate.id != null" @click="updateTemplate()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'TemplateDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      currentTemplateName: '', // Копия имени шаблона
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        // Когда диалог открывается, создаем копию имени профиля
        this.currentTemplateName = this.currentTemplate.name;
      }
    },
  },
  created() {
    this.getProfiles();
    this.getTeamLeads();
  },
  computed: {
    profiles: {
      get() {
        return this.$store.getters.GET_PROFILES;
      },
      set(value) {
        this.$store.commit("SET_PROFILES", value);
      },
    },
    teamleads() {
      return this.$store.getters.GET_TEAMLEAD_DATA;
    },
    isLoading() {
      return this.$store.getters.IS_CURRENT_TEMPLATE_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_TEMPLATE_DETAILS_MODAL_OPENED;
    },
    currentTemplate: {
      get() {
        return this.$store.getters.GET_CURRENT_TEMPLATE;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_TEMPLATE", value);
      },
    },
  },
  methods: {
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.$store.dispatch("CURRENT_TEMPLATE", {});
      this.$store.dispatch('TEMPLATE_DETAILS_MODAL', false);
    },
    createTemplate(){
      this.$store.dispatch('CREATE_TEMPLATE', this.currentTemplate)
        .then(() => {
          this.$toast.success("Шаблон успешно добавлен");
          this.$store.dispatch("CURRENT_TEMPLATE", { });
          this.$store.dispatch('TEMPLATE_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении шаблона");
        });
    },
    updateTemplate(){
      this.$store.dispatch('UPDATE_CURRENT_TEMPLATE', this.currentTemplate)
        .then(() => {
          this.$toast.success("Шаблон успешно изменен");
          this.$store.dispatch("CURRENT_TEMPLATE", { });
          this.$store.dispatch('TEMPLATE_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении шаблона");
        });
    },
    onAllUsersChange() {
      if (this.currentTemplate.user_access.all) {
        this.currentTemplate.user_access.users = [];
        this.currentTemplate.user_access.subordinates_of = [];
      }
    },
    getProfiles() {
      this.loading = true;
      const params = {
        filters: {},
        type: 'SET_PROFILES',
      };
      this.$store
        .dispatch("GET_PROFILES", params)
        .then((data) => {
        })
        .catch((error) => {
          this.$toast.error("Неизвестная ошибка!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamLeads() {
      this.loading = true;
      const params = {
        filters: { role: 'teamlead', status: 'true' },
        type: 'SET_TEAMLEADS',
      };
      this.$store
        .dispatch("GET_PROFILES", params)
        .then((data) => {})
        .catch((error) => {
          this.$toast.error("Неизвестная ошибка!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
